import React, { useState } from 'react';

function PassportScanner({ onScan, onFileChange }) {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file ? file.name : '');
    onFileChange(event); // Call the passed-in onFileChange prop
  };

  return (
    <div className="flex flex-col items-center" id="PSD">
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        id="file-upload"
        className="hidden"
      />
      <label
        htmlFor="file-upload"
        className="bg-[#da251d] hover:bg-[#b31f18] text-white font-semibold py-2 px-4 rounded cursor-pointer mb-2"
      >
        Choose File
      </label>
      {fileName && <p className="text-gray-700 mb-4">{fileName}</p>}
      <button
        onClick={onScan}
        className="bg-[#E77817] hover:bg-[#cc640f] text-white font-semibold py-2 px-4 rounded"
      >
        Scan MRZ
      </button>
    </div>
  );
}

export default PassportScanner;
