import React from 'react';

function PassportList({ results }) {
  return (
    <div id="PLD" className="mt-8">
      <h2 className="text-2xl font-semibold mb-4">Extracted Information</h2>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Surname</th>
            <th className="py-2 px-4 border-b">Given Names</th>
            <th className="py-2 px-4 border-b">Passport Number</th>
            <th className="py-2 px-4 border-b">Nationality</th>
            <th className="py-2 px-4 border-b">Date of Birth</th>
            <th className="py-2 px-4 border-b">Sex</th>
            <th className="py-2 px-4 border-b">Date of Expiry</th>
            <th className="py-2 px-4 border-b">Personal Number</th>
          </tr>
        </thead>
        <tbody>
          {results.map((data, index) => (
            <tr key={index}>
              <td className="py-2 px-4 border-b">{data.surname}</td>
              <td className="py-2 px-4 border-b">{data.givenNames}</td>
              <td className="py-2 px-4 border-b">{data.passportNumber}</td>
              <td className="py-2 px-4 border-b">{data.nationality}</td>
              <td className="py-2 px-4 border-b">{data.dateOfBirth}</td>
              <td className="py-2 px-4 border-b">{data.sex}</td>
              <td className="py-2 px-4 border-b">{data.dateOfExpiry}</td>
              <td className="py-2 px-4 border-b">{data.personalNumber}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PassportList;
