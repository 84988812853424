import React from 'react';
import { CSVLink } from 'react-csv';

function CSVDownload({ data }) {
  return (
    <div id="CSD" className="mt-4 text-center">
      <CSVLink
        data={data}
        filename="passport-data.csv"
        className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
      >
        Download CSV
      </CSVLink>
    </div>
  );
}

export default CSVDownload;
