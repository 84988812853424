import React, { useState } from 'react';
import Tesseract from 'tesseract.js';
import PassportScanner from './components/PassportScanner';
import PassportList from './components/PassportList';
import CSVDownload from './components/CSVDownload';
import './index.css'; // Ensure Tailwind CSS is imported

function App() {
  const [passportImages, setPassportImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const handleFileChange = (event) => {
    setPassportImages([...event.target.files]);
  };

  const scanPassports = () => {
    setLoading(true);
    const imagePromises = Array.from(passportImages).map(file =>
      Tesseract.recognize(file, 'eng', { logger: m => console.log(m) })
    );

    Promise.all(imagePromises)
      .then(responses => {
        const extractedData = responses.map(({ data: { text } }) => extractMRZ(text));
        setResults(extractedData.filter(data => data !== null));
      })
      .catch(error => {
        console.error('Error during OCR processing:', error);
        alert('An error occurred during OCR processing.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const extractMRZ = (text) => {
    const lines = text.split('\n').map(line => line.trim()).filter(line => line.length > 0);
    const mrzLines = lines.filter(line => line.length === 44);

    if (mrzLines.length >= 2) {
      const mrz = mrzLines.slice(-2); // Get the last two lines
      return parseMRZ(mrz);
    }
    return null;
  };

  const parseMRZ = (mrz) => {
    const [line1, line2] = mrz;

    const names = line1.substring(5).split('<<');
    const surname = names[0].replace(/</g, ' ').trim();
    const givenNames = names[1] ? names[1].replace(/</g, ' ').trim() : '';

    return {
      surname,
      givenNames,
      passportNumber: line2.substring(0, 9).replace(/</g, ''),
      nationality: line2.substring(10, 13).replace(/</g, ''),
      dateOfBirth: formatDate(line2.substring(13, 19)),
      sex: line2.substring(20, 21),
      dateOfExpiry: formatDate(line2.substring(21, 27)),
      personalNumber: line2.substring(28, 42).replace(/</g, '')
    };
  };

  const formatDate = (dateStr) => {
    const year = dateStr.substring(0, 2);
    const month = dateStr.substring(2, 4);
    const day = dateStr.substring(4, 6);
    const currentYear = new Date().getFullYear() % 100;
    const fullYear = parseInt(year, 10) > currentYear ? `19${year}` : `20${year}`;
    return `${day}/${month}/${fullYear}`;
  };

  return (
    <div className="container mx-auto p-6 bg-gradient-custom">
      <header className="text-center mb-8">
        <img src="/Logo.png" alt="Travel Fatimah Logo" className="mx-auto mb-4 max-w-xs" />
        <h1 className="text-3xl font-bold">Travel Fatimah Passport MRZ Scanner</h1>
      </header>
      
      <div className="bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-8">
        <PassportScanner onScan={scanPassports} onFileChange={handleFileChange} />
        
        {loading && (
          <div className="mt-4 text-center">
            <p className="text-gray-700">Processing images, please wait...</p>
          </div>
        )}
  
        {results.length > 0 && (
          <>
            <div className="mt-8 p-4 bg-white bg-opacity-40 backdrop-filter backdrop-blur-md rounded-lg shadow-xl">
              <PassportList results={results} />
              <CSVDownload data={results} />
            </div>
          </>
        )}
      </div>
    </div>
  );
  
  
}

export default App;
